//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
  document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' ONENESS inc. All Rights Reserved.';
};


// ヘッダー動作
var startPos = 0;
$(window).scroll(function(){
  var currentPos = $(this).scrollTop();
  if (currentPos > startPos) {
    if($(window).scrollTop() >= 100) {
      $("#header").css("top", "-" + 200 + "px");
    }
  } else {
    $("#header").css("top", 0 + "px");
  }
  startPos = currentPos;
});

// ハンバーガーメニュー
$(function () {
    $("#js_gnavMenu-openBtn").on("click", function () {
        var scrollTop = $(window).scrollTop();
        $(this).next().fadeToggle();
        $(this).toggleClass("js_gnavMenu-open");
        $('#wrapper').css({ 'position': 'fixed', 'top': -scrollTop });
    });
});
$(function () {
    var w = $(window).width();
    var scrollTop = $(window).scrollTop();
    if (w < 768) {
        $("#js_gnavMenu-closeBtn").on("click", function () {
            $('#wrapper').css({ 'position': '', 'top': '0' });
            $('html,body').scrollTop(scrollTop);
            $('#js_gnavMenu-openBtn').next().fadeToggle();
            $('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
        });
        $("#js_gnavMenu-closeSub").on("click", function () {
            $('#wrapper').css({ 'position': '', 'top': '0' });
            $('html,body').scrollTop(scrollTop);
            $('#js_gnavMenu-openBtn').next().fadeToggle();
            $('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
        });
        $("#js_gnavMenuLinkList a").on("click", function () {
            $('#wrapper').css({ 'position': '', 'top': '0' });
            $('html,body').scrollTop(scrollTop);
            $('#js_gnavMenu-openBtn').next().fadeToggle();
            $('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
        });
    }
});

// フッターの固定ボタン

$(document).ready(function(){

    // ページがスクロールされるまでは隠しておく
    $("#FixedFt").hide();

    // スクロールが始まったら
    $(window).on("scroll", function() {
        // 100pxスクロールしたら表示
        if ($(this).scrollTop() > 100) {
            $('#FixedFt').slideDown("fast");
        // ページの一番上では非表示
        } else {
            $('#FixedFt').slideUp("fast");
        }

        // ページの高さ
        var scrollHeight = $(document).height();

        //  表示画面の高さ ＋ ページに対するスクロール量
        var scrollPosition = $(window).height() + $(window).scrollTop();

        //  フッターの高さ
        var footHeight = $("#Footer").innerHeight();

        // フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
        if ( scrollHeight - scrollPosition  <= footHeight ) {

        // 現在の下から位置が、フッターの高さの位置にはいったら
        // ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする

            $("#FixedFt").css({
                "position": "absolute",
                "bottom": footHeight,
            });
        } else {
        // それ以外の場合は元のcssスタイルを指定
            $("#FixedFt").css({
                "position": "fixed",
                "bottom": "-1px",
            });
        }
    });
});

/*
// タブ
$(function() {
    $(".js_tab .tabTitle").click(function() {
        var num = $(".js_tab .tabTitle").index(this);
        $(".tabContent").removeClass('active');
        $(".tabContent").eq(num).addClass('active');
        $(".js_tab .tabTitle").removeClass('active');
        $(this).addClass('active')
    });
});
*/

// スムーズスクロール
$(function(){
   $('a[href^="#"]').click(function() {
      var speed = 300;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $('html,body').animate({scrollTop:position}, speed, 'swing');
      return false;
   });
});

// ホバーメニュー
$(function(){
  $('.hoverArea').hover(function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideDown();
    }
  },
  function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideUp();
    }
  });
});

// トグル
$(function(){
  $('.toggleBtn').click(function() {
    var w = $(window).width();
    if (w < 767) {
      $(this).toggleClass('active');
      $(this).next('.toggleContents').slideToggle();
    }
  });
});

// マウスオーバー画像変更
$(function(){
    $('.mouseover').each(function(){
        var src_off = $(this).find('.face').attr('src');
        var src_on = src_off.replace('_off','_on');
        $('<img />').attr('src',src_on);
        $(this).hover(function(){
            $(this).find('.face').stop(true , true).animate({opacity:'0.1'}, 3000 );
            $(this).find('.face').attr('src',src_on);
            $(this).find('.face').stop(true , true).animate({opacity:'1'}, 1000 );
        },function(){
            $(this).find('.face').stop(true , true).animate({opacity:'0.1'}, 3000 );
            $(this).find('.face').attr('src',src_off);
            $(this).find('.face').stop(true , true).animate({opacity:'1'}, 1000 );
        }); 
    });
});


